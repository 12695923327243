<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="pl-1 primary--text">{{
          appId != null ? formEditTitle : formNewTitle
        }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-form
            v-model="isFormValid"
            ref="crud-form"
            id="crud-form"
            @submit.prevent="saveEdit()"
          >
            <v-row>
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="Nombre"
                  v-model.trim="appNombre"
                  outlined
                  :rules="
                    rules.required.concat([
                      rules.requiredTrim(appNombre),
                      rules.maxLength(appNombre, 100)
                    ])
                  "
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  v-model.trim="appCodigo"
                  label="Código"
                  dense
                  outlined
                  :rules="
                    rules.required.concat([
                      rules.requiredTrim(appCodigo),
                      rules.maxLength(appCodigo, 200)
                    ])
                  "
                  autocomplete="off"
                ></v-text-field>
              </v-col>
              <!-- Convenios -->
              <v-col cols="12" class="py-0">
                <v-select
                  outlined
                  clearable
                  dense
                  multiple
                  item-text="value"
                  item-value="id"
                  return-object
                  v-model="convenioSelected"
                  label="Convenio/s"
                  :items="convenios"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="conveniosToggle()">
                      <v-list-item-action>
                        <v-icon
                          :color="convenioSelected.length > 0 ? 'primary' : ''"
                        >
                          {{ multiselectIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Todos </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.value }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ convenioSelected.length - 1 }} otros)
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="crud-form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";

export default {
  props: ["appId", "apps"],
  data() {
    return {
      rules: rules,
      isFormValid: false,
      convenioSelected: [],
      convenios: [],
      formNewTitle: enums.titles.NUEVA_CONFIGURACION_APP,
      formEditTitle: enums.titles.EDITAR_CONFIGURACION_APP,
      appNombre: null,
      sameCode: null,
      appCodigo: null
    };
  },
  created() {
    if (this.appId != null) {
      this.setApp(this.appId);
    }
    this.setConvenios();
  },
  computed: {
    multiselectIcon() {
      if (this.selectAllConvenios) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllConvenios() {
      return (
        this.convenioSelected &&
        this.convenioSelected.length === this.convenios.length
      );
    }
  },
  methods: {
    ...mapActions({
      getAppsPublicadasById: "configAppBenef/getAppsPublicadasById",
      saveAppsPublicadas: "configAppBenef/saveAppsPublicadas",
      getAppsPublicadas: "configAppBenef/getAppsPublicadas",
      getConvenios: "afiliaciones/getConvenios",
      setAlert: "user/setAlert"
    }),
    conveniosToggle() {
      this.$nextTick(() => {
        if (this.selectAllConvenios) {
          this.convenioSelected = [];
        } else {
          this.convenioSelected = this.convenios;
        }
      });
    },
    async setConvenios() {
      const response = await this.getConvenios();
      this.convenios = response;
    },
    async setApp() {
      const response = await this.getAppsPublicadasById(this.appId);
      this.appNombre = response.appNombre;
      this.appCodigo = response.appCodigo;
      this.convenioSelected = response.obrasSociales;
    },
    async saveEdit() {
      const sameCodeApp = this.apps.find(
        x => x.appId != this.appId && x.appCodigo === this.appCodigo
      );
      if (sameCodeApp) {
        this.sameCode = true;
        this.setAlert({
          type: "warning",
          message: "No puede haber aplicaciones con el mismo código"
        });
      } else {
        this.sameCode = false;
      }
      if (this.sameCode === false) {
        const obrasSociales = this.convenioSelected.map(x => x.id);
        const data = {
          appId: this.appId,
          appNombre: this.appNombre,
          appCodigo: this.appCodigo,
          obrasSociales: obrasSociales
        };
        const res = await this.saveAppsPublicadas(data);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
        }
        this.closeModal();
      }
    },
    closeModal() {
      this.editTipoDialog = false;
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style></style>
