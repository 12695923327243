<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-data-table
          :headers="headers"
          :items="apps"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.apps`]="{ item }">
            <span>{{ addApp(item.apps) }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item.appId)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteApp(item.appId)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-dialog
          v-if="editTipoDialog"
          v-model="editTipoDialog"
          max-width="500px"
          @keydown.esc="closeModal"
          persistent
        >
          <EditApp
            :appId="appId"
            :apps="apps"
            @closeAndReload="closeAndReload"
          />
        </v-dialog>
        <DeleteDialog
          :titleProp="titleDelete"
          :openDelete.sync="showDeleteModal"
          @onDeleteItem="confirmDelete()"
        />
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditApp from "@/components/modules/appBenef/configuracion/EditConfiguracionApp";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "ConfiguracionApps",
  components: { PageHeader, DeleteDialog, EditApp, GoBackBtn, Ayuda },

  data() {
    return {
      search: "",
      searchIcon: enums.icons.SEARCH,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      showDeleteModal: false,
      itemSelected: null,
      routeToGo: "ConfiguracionAppBenef",
      idToDelete: null,
      editTipoDialog: false,
      title: enums.titles.CONFIGURACION_APP,
      optionCode: enums.webSiteOptions.CONFIGURACION_APP,
      showExpand: false,
      showHelp: false,
      showIcon: true,
      titleDelete: "¿Desea eliminar app?",
      apps: [],
      appId: null,
      headers: [
        {
          text: "Nombre",
          value: "appNombre"
        },
        {
          text: "Código",
          value: "appCodigo"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      allowedActions: null,
      canEdit: false,
      canDelete: false,
      canCreate: false
    };
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadApps();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      deleteAppsPublicadas: "configAppBenef/deleteAppsPublicadas",
      getAppsPublicadas: "configAppBenef/getAppsPublicadas",
      setAlert: "user/setAlert"
    }),
    async loadApps() {
      const response = await this.getAppsPublicadas();
      this.apps = response;
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .ELIMINAR_CONFIGURACION_APP:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_CONFIGURACION_APP:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_CONFIGURACION_APP:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async confirmDelete() {
      const response = await this.deleteAppsPublicadas({
        appId: this.idToDelete
      });
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadApps();
      }
    },
    deleteApp(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    openModal(appId) {
      this.editTipoDialog = true;
      this.appId = appId;
    },
    closeModal() {
      this.editTipoDialog = false;
    },
    closeAndReload() {
      this.editTipoDialog = false;
      this.loadApps();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
