var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.appId != null ? _vm.formEditTitle : _vm.formNewTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"crud-form",attrs:{"id":"crud-form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveEdit()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre","outlined":"","rules":_vm.rules.required.concat([
                    _vm.rules.requiredTrim(_vm.appNombre),
                    _vm.rules.maxLength(_vm.appNombre, 100)
                  ]),"dense":""},model:{value:(_vm.appNombre),callback:function ($$v) {_vm.appNombre=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"appNombre"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Código","dense":"","outlined":"","rules":_vm.rules.required.concat([
                    _vm.rules.requiredTrim(_vm.appCodigo),
                    _vm.rules.maxLength(_vm.appCodigo, 200)
                  ]),"autocomplete":"off"},model:{value:(_vm.appCodigo),callback:function ($$v) {_vm.appCodigo=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"appCodigo"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","clearable":"","dense":"","multiple":"","item-text":"value","item-value":"id","return-object":"","label":"Convenio/s","items":_vm.convenios},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.conveniosToggle()}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.convenioSelected.length > 0 ? 'primary' : ''}},[_vm._v(" "+_vm._s(_vm.multiselectIcon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Todos ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
                  var item = ref.item;
                  var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.value))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.convenioSelected.length - 1)+" otros) ")]):_vm._e()]}}]),model:{value:(_vm.convenioSelected),callback:function ($$v) {_vm.convenioSelected=$$v},expression:"convenioSelected"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"crud-form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }